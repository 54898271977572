export enum CChannelTypeEnum {
    Widget = 1,
    Telegram = 2,
    Viber = 3,
    Instagram = 4,
    Messenger = 5,
    WhatsApp = 6
}

export enum CChannelWidgetButtonShapeEnum {
    Square = 'S',
    RoundSquare = 'R',
    Circle = 'C'
}

export enum CChannelWidgetButtonIconEnum {
    One = 1,
    Two = 2,
    Three = 3
}

export enum CChannelWidgetPositionEnum {
    Left = 'L',
    Right = 'R'
}

export enum CChannelWidgetFavoriteMode {
    Popular = 'popular',
    Custom = 'custom'
}

export enum CChannelWidgetVisitorContactMode {
    AfterFirstMessage = 'after_first_message',
    BeforeFirstMessage = 'before_first_message'
}
